import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const MiddleScreen = () => {
  const userstate = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userstate;

  // Define a common style object for button shadows
  const buttonShadowStyle = {
    boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.5)",
    transition: "background-color 0.3s",
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div style={{ paddingTop: "70px" }} className="position-relative">
      <button
        className="btn btn-primary"
        style={{
          ...buttonShadowStyle,
          backgroundColor: "#007bff",
          display: currentUser ? "none" : "",
        }}
      >
        <Link
          to="/login"
          className="text-white text-decoration-none"
          style={{ color: "#fff" }}
          state={{
            page: "/middlescreen",
          }}
        >
          Login
        </Link>
      </button>
      {currentUser ? (
        <button
          className="btn btn-success mx-2"
          style={{
            ...buttonShadowStyle,
            backgroundColor: "#28a745",
            pointerEvents: "none",
            opacity: "0.5",
            display: currentUser ? "none" : "",
          }}
          disabled
        >
          Registration
        </button>
      ) : (
        <button
          className="btn btn-success mx-2"
          style={{
            ...buttonShadowStyle,
            backgroundColor: "#28a745",
            display: currentUser ? "none" : "",
          }}
        >
          <Link
            to="/register"
            className="text-white text-decoration-none"
            style={{ color: "#fff" }}
          >
            Registration
          </Link>
        </button>
      )}
      <div
        className="text-center mt-5"
        style={{ paddingBottom: "450px", left: isMobile ? "10px" : "200px" }}
      >
        {currentUser ? (
          <Link
            to="/bauddhadakshata"
            className="btn btn-info btn-lg rounded-circle p-5 position-absolute start-50 translate-middle-x"
            style={{
              ...buttonShadowStyle,
              bottom: "50px",
              border: "2px black",
            }}
          >
            <span style={{ fontSize: "24px" }}>Quiz</span>
          </Link>
        ) : (
          <button
            className="btn btn-info btn-lg rounded-circle p-5 position-absolute start-50 translate-middle-x"
            style={{
              ...buttonShadowStyle,
              bottom: "10px",
              border: "2px black",
              pointerEvents: "none",
              opacity: "0.5",
              backgroundColor: "#17a2b8",
            }}
            disabled
          >
            <span style={{ fontSize: "24px" }}>Quiz</span>
          </button>
        )}
      </div>
      {!isMobile && (
        <>
          <img
            src={"mental.gif"}
            alt=""
            style={{
              position: "absolute",
              bottom: "120px",
              left: "70px",
              width: "350px",
              height: "350px",
              boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
            }}
          />
        </>
      )}
      <img
        src={"hello_welcome.png"}
        alt=""
        style={{
          position: "absolute",
          bottom: "200px",
          left: isMobile ? "0px" : "500px",
          right: isMobile ? "50px" : "0px",
          width: isMobile ? "100%" : "500px",
          height: isMobile ? "auto" : "250px",
          maxHeight: "250px",
          margin: "20px",
          padding: "10px",
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
        }}
      />
      <p
        style={{
          position: "absolute",
    top: "600px",
    width: "100%",
    textAlign: "center",
    fontSize: "18px",
    color: "#666",
    fontFamily: '"Libre Baskerville", serif',
    padding: "20px",
    backgroundColor: "#D3D3D3",
    borderRadius: "10px",
    boxShadow: "4px 4px 4px 8px rgba(0, 0, 0, 0.5)",
    border:"1px solid black",
    margin: "5px 5px 5px 5px",
    maxWidth: "100%",
        }}
      ><strong>Disclaimer :</strong><br></br>
        All the information published here is in good faith and for general
        information purpose only. Every effort has been made to make sure that
        the information presented here is accurate and correct but the website
        (company) does not make any warranties about the completeness,
        reliability and accuracy of this information. Any action you take upon
        the information on this website, is strictly at your own risk. The
        company will not be liable for any losses and/or damages in connection
        with the use of the content. The information being shared here is in
        good faith to enhance the knowledge and skills of students and is not
        intended to hurt anyone’s feelings and emotions.
      </p>
    </div>
  );
};

export default MiddleScreen;
