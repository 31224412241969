import React from "react";
import "./Firstscreen.css";
import Ring from "./Ring";
import MediaContainer from "./MediaContainer";
import ProductOfferings from "./ProductOfferings";
import LearningOutcomes from "./LearningOutcomes";
import ColorfulBubbles from "./ColorfulBubbles";
import StatisticsCounter from "./StatisticsCounter";
import FooterInfo from "./FooterInfo";
import TeacherTraining from "./TeacherTraining";

function Firstscreen() {
  return (
    <div>
      <Ring />
      <div id="media-container"><MediaContainer /></div>
      <ProductOfferings />
      <LearningOutcomes />
      <ColorfulBubbles />
      <TeacherTraining />
      <StatisticsCounter />
      <div id="footer-info">
        <FooterInfo />
      </div>
    </div>
  );
}

export default Firstscreen;
////////////////////////////////////////////////////////////////////////////////
