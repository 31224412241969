import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

const offerings = [
  {
    title: "Udbodh",
    description:
      "A broad base of diversified knowledge can help students think critically and creatively leading to personal and professional growth.",
  },
  {
    title: "Bauddha Dakshata",
    description:
      "Education in analytical skills and logical reasoning helps students develop their problem-solving skills and critical thinking abilities.",
  },
  {
    title: "Inspiration Stories",
    description: "Motivational and inspiring stories of our young students.",
  },
  {
    title: "Financial Literacy",
    description: "Facilitating financial literacy amongst students.",
  },
  {
    title: "Teacher Training Programs",
    description: "",
  },
  {
    title: "ABL",
    description: "Incorporating art and activities in learning.",
  },
  {
    title: "Parent Orientation Program",
    description: "",
  },
  {
    title: "Samanvaya",
    description: "",
  },
  {
    title: "Remedial Classes",
    description: "",
  },
];

function ProductOfferings() {
  const headingRef = useRef(null);
  const cardsRef = useRef([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 767);
  const navigate = useNavigate();

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 767);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }

    cardsRef.current.forEach((card) => {
      if (card) observer.observe(card);
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  const handleClick = (title) => {
    window.location.href =
      title == "Udbodh"
        ? "/middlescreen2"
        : title == "Bauddha Dakshata"
        ? "/middlescreen"
        : title == "Inspiration Stories"
        ? "/getinspired"
        : title == "Samanvaya"
        ? "https://knowandbuildbharat.com"
        : "#";
    //navigate(title === "Udbodh" ? "/middlescreen2" : "#"); // Navigates to the 'About' page
  };

  return (
    <div
      style={{
        padding: "40px 0",
        background: "#f8f9fa",
        marginTop: isMobile ? "60vh" : "",
      }}
    >
      <h2
        ref={headingRef}
        className="heading"
        style={{
          textAlign: "center",
          fontFamily: '"Libre Baskerville", serif',
          fontSize: "2em",
          fontWeight: "bold",
          marginTop: "5vh",
          marginBottom: "2vh",
          width: "100%",
        }}
      >
        Our Product Offerings
      </h2>
      <div
        className="card-container"
        style={{
          display: "flex",
          justifyContent: "space-around",
          alignItems: "flex-start",
          flexWrap: "wrap",
          padding: "20px",
          marginTop: "50px",
          background: "white",
        }}
      >
        {offerings.map((offering, index) => (
          <div
            key={index}
            ref={(el) => (cardsRef.current[index] = el)}
            className="card"
            onClick={() => handleClick(offering.title)}
          >
            <div
              className="card-title"
              style={{
                textAlign: "center",
                fontFamily: '"Libre Baskerville", serif',
                fontSize: "2em",
                fontWeight: "bold",
                marginTop:
                  offering.title === "Teacher Training Programs" ||
                  offering.title === "Financial Literacy" ||
                  offering.title === "ABL" ||
                  offering.title === "Parent Orientation Program"
                    ? "10vh"
                    : offering.title === "Samanvaya" ||
                      offering.title === "Remedial Classes"
                    ? "15vh"
                    : "5vh",
                marginBottom: "2vh",
                width: "100%",
              }}
            >
              {offering.title}
            </div>
            <div
              className="card-description"
              style={{
                fontFamily: '"Libre Baskerville", serif',
                fontWeight: "bold",
              }}
            >
              {offering.description}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default ProductOfferings;
