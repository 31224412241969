// import React, { useState, useEffect, useRef } from "react";
// import { useSelector, useDispatch } from "react-redux";
// import axios from "axios"; // To make API requests
// import Error from "../components/Error";
// import Loading from "../components/Loading";

// import { deleteUser, updatePaidStatus } from "../actions/userActions";

// export default function UsersList() {
//   const dispatch = useDispatch();
//   const [selectedSchoolCode, setSelectedSchoolCode] = useState("");
//   const [filteredUsers, setFilteredUsers] = useState([]); // State to store filtered users
//   const [page, setPage] = useState(1); // Page number for pagination
//   const [loading, setLoading] = useState(false);
//   const [error, setError] = useState("");
//   const [hasMore, setHasMore] = useState(true); // To track if there are more users to load

//   const userstate = useSelector((state) => state.loginUserReducer);
//   const { currentUser } = userstate;

//   const schoolcodesState = useSelector(
//     (state) => state.getAllSchoolcodesReducer
//   );
//   const { schoolcodes } = schoolcodesState;

//   const observer = useRef(); // Ref to store the observer instance
//   const triggerRef = useRef(); // Ref to the bottom div that will trigger API call

//   useEffect(() => {
//     // Fetch users when page changes
//     if (page > 1) {
//       if(selectedSchoolCode!==""){
//         fetchUsersBySchoolCode();
//       }
//     }
//   }, [page]);

//   const handlePaidChange = async (userId, paid) => {
//     // try {
//     //   setLoading(true);
//     //   await axios.post("/api/users/updatepaidstatus", { userId, paid });
//     //   setLoading(false);
//     // } catch (error) {
//     //   setError("Error updating paid status.");
//     //   setLoading(false);
//     // }
//     //dispatch(updatePaidStatus(userId, paid));
//     try {
//       // Optimistically update the UI immediately after checkbox click
//       setFilteredUsers((prevUsers) =>
//         prevUsers.map((user) =>
//           user._id === userId ? { ...user, paid: paid } : user
//         )
//       );

//       // Make the API call to update the paid status in the backend
//       await axios.post("/api/users/updatepaidstatus", { userId, paid });
//     } catch (error) {
//       setError("Error updating paid status.");
//     }
//   };

//   // Handler for fetching users based on selected schoolcode
//   const fetchUsersBySchoolCode = async (isFirstLoad=false) => {
//     try {
//       setLoading(true);
//       setError("");

//       // Prepare the schoolcode value
//       const schoolcodeToSearch =selectedSchoolCode;

//       // Make the API call to get users by schoolcode with pagination
//       const response = await axios.post("/api/users/getusersbyschoolcode", {
//         schoolcode: schoolcodeToSearch,
//         page,
//         limit: 5, // Fetch 10 users at a time
//       });

//       const newUsers = response.data;

//       // If there are no more users, set hasMore to false
//       if (newUsers.length === 0) {
//         setHasMore(false);
//       }

//       // For the first load, replace the list. Otherwise, append.
//     if (isFirstLoad) {
//       setFilteredUsers(newUsers); // Replace users for the first load
//     } else {
//       setFilteredUsers((prevUsers) => [...prevUsers, ...newUsers]); // Append users on subsequent pages
//     }
//       setLoading(false);
//     } catch (err) {
//       setError("Failed to fetch users.");
//       setLoading(false);
//     }
//   };

//   // Load more users when the trigger div is visible
//   useEffect(() => {
//     if (loading || !hasMore) return; // Don't set up the observer if loading or no more users

//     const observerCallback = (entries) => {
//       const [entry] = entries; // Get the first entry
//       if (entry.isIntersecting) {
//         setPage((prevPage) => prevPage + 1); // Load the next page when the trigger div is in view
//       }
//     };

//     const options = {
//       root: null, // Use the viewport as the root
//       threshold: 1.0, // Trigger when the trigger div is fully visible
//     };

//     observer.current = new IntersectionObserver(observerCallback, options);

//     if (triggerRef.current) {
//       observer.current.observe(triggerRef.current); // Observe the trigger div
//     }

//     return () => {
//       if (observer.current && triggerRef.current) {
//         observer.current.unobserve(triggerRef.current); // Clean up the observer when the component unmounts
//       }
//     };
//   }, [loading, hasMore, filteredUsers]);

//   // Initial fetch when "Show Users" button is clicked
//   const handleShowUsers = () => {
//     setFilteredUsers([]); // Clear previous users
//     setPage(1); // Reset to the first page
//     setHasMore(true); // Reset hasMore
//     setLoading(false);
//     setError("");
//     if(selectedSchoolCode!==""){
//       fetchUsersBySchoolCode(true);
//     }
//   };

//   return (
//     <div>
//       <hr></hr>
//       <h1>Online Users List</h1>
//       <hr></hr>

//       {/* Schoolcode Dropdown */}
//       <div className="form-group">
//         <label></label>
//         <select
//           className="form-control"
//           value={selectedSchoolCode}
//           onChange={(e) => setSelectedSchoolCode(e.target.value)}
//           style={{ marginBottom: "10px" }}
//         >
//           <option value="">Select School Code</option>
//           {schoolcodes &&
//             schoolcodes.map((code) => (
//               <option key={code._id} value={code.code}>
//                 {code.code}
//               </option>
//             ))}
//         </select>
//       </div>

//       {/* Show Users Button */}
//       <button className="btn btn-primary mb-3" onClick={handleShowUsers}>
//         Show Users
//       </button>

//       {loading && <Loading />}
//       {error && <Error error={error} />}

//       <div className="table-responsive">
//         <table className="table table-striped table-bordered">
//           <thead className="table-dark">
//             <tr>
//               <th className="p-3">UserId</th>
//               <th className="p-3">Name</th>
//               <th className="p-3">Email</th>
//               <th className="p-3">School</th>
//               <th className="p-3">School Code</th>
//               <th className="p-3">Class</th>
//               <th className="p-3">Section</th>
//               <th className="p-3">Mobile No.</th>
//               <th className="p-3">City</th>
//               <th className="p-3">Paid</th>
//               {currentUser.isAdmin ? <th className="p-3">Delete</th> : ""}
//             </tr>
//           </thead>
//           <tbody>
//             {filteredUsers &&
//               filteredUsers.map((user) => (
//                 <tr key={user._id}>
//                   <td className="p-3">{user._id}</td>
//                   <td className="p-3">{user.name}</td>
//                   <td className="p-3">{user.email}</td>
//                   <td className="p-3">{user.school}</td>
//                   <td className="p-3">{user.schoolcode}</td>
//                   <td className="p-3">{user.classs}</td>
//                   <td className="p-3">{user.section}</td>
//                   <td className="p-3">{user.mobile}</td>
//                   <td className="p-3">{user.city}</td>
//                   <td className="p-3">
//                     <input
//                       type="checkbox"
//                       checked={user.paid}
//                       onChange={(e) =>
//                         handlePaidChange(user._id, e.target.checked)
//                       }
//                     />
//                   </td>
//                   {currentUser.isAdmin ? (
//                     <td className="p-3">
//                       <i
//                         className="fa fa-trash"
//                         onClick={() => {
//                           // Call delete API here
//                           dispatch(deleteUser(user._id));
//                         }}
//                         style={{ cursor: "pointer" }}
//                       ></i>
//                     </td>
//                   ) : (
//                     ""
//                   )}
//                 </tr>
//               ))}
//           </tbody>
//         </table>
//       </div>

//       {/* Trigger div at the bottom of the page */}

//       <p ref={triggerRef} style={{ color: "white" }}>
//         .
//       </p>

//       {loading && <p>Loading more users...</p>}
//       {!hasMore && <p>No more users to display</p>}
//     </div>
//   );
// }
////////////////////////////////////////////////////////////////////////////////
import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios"; // To make API requests
import Error from "../components/Error";
import Loading from "../components/Loading";

import { deleteUser, updatePaidStatus } from "../actions/userActions";

export default function UsersList() {
  const dispatch = useDispatch();
  const [filteredUsers, setFilteredUsers] = useState([]); // State to store filtered users
  const [page, setPage] = useState(1); // Page number for pagination
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [hasMore, setHasMore] = useState(true); // To track if there are more users to load

  const userstate = useSelector((state) => state.loginUserReducer);
  const { currentUser } = userstate;

  const observer = useRef(); // Ref to store the observer instance
  const triggerRef = useRef(); // Ref to the bottom div that will trigger API call

  useEffect(() => {
    fetchUsers(); // Fetch users when component mounts and page changes
  }, [page]);

  const handlePaidChange = async (userId, paid) => {
    try {
      // Optimistically update the UI immediately after checkbox click
      setFilteredUsers((prevUsers) =>
        prevUsers.map((user) =>
          user._id === userId ? { ...user, paid: paid } : user
        )
      );

      // Make the API call to update the paid status in the backend
      await axios.post("/api/users/updatepaidstatus", { userId, paid });
    } catch (error) {
      setError("Error updating paid status.");
    }
  };

  // Fetch users and sort by createdAt (newest first)
  const fetchUsers = async () => {
    try {
      setLoading(true);
      setError("");

      // Make the API call to get users with pagination
      const response = await axios.post("/api/users/getusersbyschoolcode", {
        schoolcode: "",
        page,
        limit: 5, // Fetch 5 users at a time
      });

      const newUsers = response.data;

      // If there are no more users, set hasMore to false
      if (newUsers.length === 0) {
        setHasMore(false);
      }

      setFilteredUsers((prevUsers) => [...prevUsers, ...newUsers]); // Append users on subsequent pages
      setLoading(false);
    } catch (err) {
      setError("Failed to fetch users.");
      setLoading(false);
    }
  };

  // Load more users when the trigger div is visible
  useEffect(() => {
    if (loading || !hasMore) return; // Don't set up the observer if loading or no more users

    const observerCallback = (entries) => {
      const [entry] = entries; // Get the first entry
      if (entry.isIntersecting) {
        setPage((prevPage) => prevPage + 1); // Load the next page when the trigger div is in view
      }
    };

    const options = {
      root: null, // Use the viewport as the root
      threshold: 1.0, // Trigger when the trigger div is fully visible
    };

    observer.current = new IntersectionObserver(observerCallback, options);

    if (triggerRef.current) {
      observer.current.observe(triggerRef.current); // Observe the trigger div
    }

    return () => {
      if (observer.current && triggerRef.current) {
        observer.current.unobserve(triggerRef.current); // Clean up the observer when the component unmounts
      }
    };
  }, [loading, hasMore, filteredUsers]);

  return (
    <div>
      <hr />
      <h1>Online Users List</h1>
      <hr />

      {loading && <Loading />}
      {error && <Error error={error} />}

      <div className="table-responsive">
        <table className="table table-striped table-bordered">
          <thead className="table-dark">
            <tr>
              <th className="p-3">UserId</th>
              <th className="p-3">Name</th>
              <th className="p-3">Email</th>
              <th className="p-3">School</th>
              <th className="p-3">School Code</th>
              <th className="p-3">Class</th>
              <th className="p-3">Section</th>
              <th className="p-3">Mobile No.</th>
              <th className="p-3">City</th>
              <th className="p-3">Paid</th>
              {currentUser.isAdmin ? <th className="p-3">Delete</th> : ""}
            </tr>
          </thead>
          <tbody>
            {filteredUsers &&
              filteredUsers.map((user) => (
                <tr key={user._id}>
                  <td className="p-3">{user._id}</td>
                  <td className="p-3">{user.name}</td>
                  <td className="p-3">{user.email}</td>
                  <td className="p-3">{user.school}</td>
                  <td className="p-3">{user.schoolcode}</td>
                  <td className="p-3">{user.classs}</td>
                  <td className="p-3">{user.section}</td>
                  <td className="p-3">{user.mobile}</td>
                  <td className="p-3">{user.city}</td>
                  <td className="p-3">
                    <input
                      type="checkbox"
                      checked={user.paid}
                      onChange={(e) =>
                        handlePaidChange(user._id, e.target.checked)
                      }
                    />
                  </td>
                  {currentUser.isAdmin ? (
                    <td className="p-3">
                      <i
                        className="fa fa-trash"
                        onClick={() => {
                          // Call delete API here
                          dispatch(deleteUser(user._id));
                        }}
                        style={{ cursor: "pointer" }}
                      ></i>
                    </td>
                  ) : (
                    ""
                  )}
                </tr>
              ))}
          </tbody>
        </table>
      </div>

      {/* Trigger div at the bottom of the page */}
      <p ref={triggerRef} style={{ color: "white" }}>
        .
      </p>

      {loading && <p>Loading more users...</p>}
      {!hasMore && <p>No more users to display</p>}
    </div>
  );
}
