import React, { useRef, useEffect, useState } from "react";
import "./cb.css";

function ColorfulBubbles() {
  const headingRef = useRef(null);
  const bubblesRef = useRef([]);
  const [isvisible, setisvisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            setisvisible(true);
            entry.target.classList.add("animate");
            observer.unobserve(entry.target);
          }
        });
      },
      { threshold: 0.5 }
    );

    if (headingRef.current) {
      observer.observe(headingRef.current);
    }
    bubblesRef.current.forEach((bubble) => bubble && observer.observe(bubble));

    return () => observer.disconnect();
  }, []);

  return (
    <>
      <h2
        ref={headingRef}
        style={{
          textAlign: "center",
          fontFamily: '"Libre Baskerville", serif',
          fontSize: "2em",
          fontWeight: "bold",
          marginTop: "5vh",
          marginBottom: "2vh",
          width: "100%",
        }}
      >
        Program Design
      </h2>
      <div className="bubbles-container">
        {["#389e2d", "#1E90FF", "#FF8C00"].map((color, index) => (
          <div
            key={color}
            ref={(el) => (bubblesRef.current[index] = el)}
            className={`bubble bubble-${index + 1}`}
            style={{
              backgroundColor: color,
              opacity: isvisible ? 0.75 : 0,
              visibiliy: isvisible ? "visible" : "hidden",
            }}
          >
            <h2>{["Curriculum", "Pedagogy", "Success Factors"][index]}</h2>
            <p>
              {
                [
                  "Curriculum across domains, designed by India's leading practitioners, entrepreneurs, and academics to make you as an individual stand out.",
                  "A rich range of learning approaches, including interactive lectures, online content, discussion based learning, brain teasers, and puzzles and skill drills for deep proficiency.",
                  "Significant transformation with increased diversified knowledge and ability to critically analyze and evaluate information. Enhanced interest development in other core subjects leading to a well-groomed and confident personality.",
                ][index]
              }
            </p>
          </div>
        ))}
      </div>
      <br />
    </>
  );
}

export default ColorfulBubbles;
//////////////////////////////////////////////////////////////////////
